body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,h2,h3,h4{
  font-weight: 100;
}

h3{
  font-weight: 100 !important;
}

h5,span,p,ul,li{
  font-weight: 400;
}

.read-more-button{
  color: black;
  font-weight: bold;
  cursor: pointer;
}

.slick-prev, .slick-next{
  /* width: 10% !important; */
  /* height: 40vh !important; */
  top: 40% !important;
}

.slick-prev {
  /* width: 10% !important; */
  /* height: max-content !important; */
  /* background: linear-gradient(to right, rgba(35,31,32,1) 0%,rgba(35,31,32,0) 100%) !important; */
}

.slick-next{
  /* width: 10% !important; */
  /* height: max-content !important; */
  /* background: linear-gradient(to left, rgba(35,31,32,1) 0%,rgba(35,31,32,0) 100%) !important; */
}

.slick-prev:before{
  /* content: "<" !important; */
}

.slick-next:before{
  /* content: ">" !important; */
}

.slick-prev:before, .slick-next:before {
  font-size: 0rem !important;
  /* font-family: 'Roboto' !important ;
  opacity: .3 !important;
  position: absolute;
  top: 40%; */
}

.slick-dots li button:before {
  color:lightgrey !important;
  opacity: .75 !important;
  font-size: 12px !important;
  z-index: 1 !important;
}

.slick-dots li.slick-active button:before {
  color: white !important;
  opacity: 1 !important;
}

.slick-initialized .slick-slide {
  outline: none;
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 3rem !important;
  }
  
  h3 {
    font-size: 2.5rem !important;
  }

  h4 {
    font-size: 1.75rem !important;
  }

  h5 {
    font-size: 1.25rem !important;
  }
}